.form-login-container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    align-content: center; 
    justify-content: center; 
    overflow: auto;  
}

.form-login {
    width: 420px;
    max-width: 420px;
    padding: 15px;
    margin: auto;
}

.form-login .form-label-group {
    position: relative;
    margin-bottom: 1rem;
}
  
.form-login .form-label-group > input,
.form-login .form-label-group > label {
    height: 3.125rem;
    padding: .75rem;
}
  
.form-login .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0; /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    pointer-events: none;
    cursor: text; /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
}
  
.form-login .form-label-group input::-webkit-input-placeholder {
    color: transparent;
}
  
.form-login .form-label-group input:-ms-input-placeholder {
    color: transparent;
}
  
.form-login .form-label-group input::-ms-input-placeholder {
    color: transparent;
}
  
.form-login .form-label-group input::-moz-placeholder {
    color: transparent;
}
  
.form-login .form-label-group input::placeholder {
    color: transparent;
}

.form-login .form-label-group input:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}
  
.form-login .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
}
  
/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
    .form-login .form-label-group > label {
        display: none;
    }
    .form-login .form-label-group input::-ms-input-placeholder {
        color: #777;
    }
}
  
/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .form-login .form-label-group > label {
        display: none;
    }
    .form-login .form-label-group input:-ms-input-placeholder {
        color: #777;
    }
}
  
.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}

.form-login .alert-container {
    padding-top:20px;
    padding-bottom: 40px;
}