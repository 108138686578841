body {
  /*display: -ms-flexbox;
  ms-flex-align: center;
  align-items: center;*/
  padding-top: 0px;
  padding-bottom: 0px;
  /*background-color: #f5f5f5;*/
  
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}

@media screen and (max-width:768px) {
  body {
    font-size: 13px;
  }
  h6, .h6 {
    font-size: 13px;
  }
  h5, .h5 {
    font-size: 16px;
  }
  h4, .h4 {
    font-size: 18px;
  }
  h3, .h3 {
    font-size: 20px;
  }
  h2, .h2 {
    font-size: 22px;
  }
  h1, .h1 {
    font-size: 24px;
  }
  .lead {
    font-size: 16px;
  }
  .dhx_cell-content, .dhx_grid-header-cell-text {
    font-size: 13px;
  }
}
